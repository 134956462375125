import {useState} from "react";
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import {ThemeProvider} from "@mui/material";
import axios from "axios";

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '450px',
    },
    boxShadow:
        'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    ...theme.applyStyles('dark', {
        boxShadow:
            'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    }),
}));


const SignInContainer = styled(Stack)(({ theme }) => ({
    height: 'calc((1 - var(--template-frame-height, 0)) * 100dvh)',
    minHeight: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
    },
    '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: -1,
        inset: 0,
        backgroundImage:
            'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
        backgroundRepeat: 'no-repeat',
        ...theme.applyStyles('dark', {
            backgroundImage:
                'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
        }),
    },
}));


export const Login = (props)=> {
    const [email, setEmail] = useState(localStorage.getItem('gizmoai_user') ? localStorage.getItem('gizmoai_user') : '')
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")
    const [config, setConfig] = useState(localStorage.getItem('gizmoai_access_token') ? JSON.parse(localStorage.getItem('gizmoai_access_token')) : {})

    function logout(){
        localStorage.removeItem('gizmoai_access_token')
        localStorage.removeItem('gizmoai_user')
        setConfig({});
    }

    async function handleSubmit(e){
        e.preventDefault()
        try {
            const data = {
                user: email,
                pass: password,
            };
            //let url = window.location.protocol + "//" + window.location.hostname;
            axios.post('gizmoai/login', data, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                })
                .then(({data}) => {
                    console.log(data);
                    if ( data?.token ) {
                        setConfig(data);
                        localStorage.setItem('gizmoai_access_token', JSON.stringify(data));
                        localStorage.setItem('gizmoai_user', email);
                    }
                    else{
                        setError('Invalid response.');
                        localStorage.removeItem('gizmoai_access_token')
                        localStorage.removeItem('gizmoai_user')
                    }
                }).catch(e=>{
                    console.error(e);
                    setError('Login Failed.');
                    localStorage.removeItem('gizmoai_access_token', '')
                    localStorage.removeItem('gizmoai_user', '')
            });
        }
        catch (error) {
            console.log(error);
        }
    }

    return (
        <ThemeProvider theme={props.theme}>
            <CssBaseline enableColorScheme />
            { !config?.token ? <SignInContainer direction="column" justifyContent="space-between">
                <Card variant="outlined">
                    <Typography
                        component="h1"
                        variant="h4"
                        sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
                    >
                        Sign in
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: 2,
                        }}
                    >
                        <FormControl>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <FormLabel htmlFor="email">Email</FormLabel>
                            </Box>

                            <TextField
                                id="email"
                                type="email"
                                name="email"
                                placeholder="Email"
                                autoComplete="email"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                color={'primary'}
                                sx={{ ariaLabel: 'email' }}
                                value={email}
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <FormLabel htmlFor="password">Password</FormLabel>
                            </Box>
                            <TextField
                                name="password"
                                placeholder="••••••"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                color={'primary'}
                                value={password}
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                }}
                            />
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Sign in
                        </Button>
                        <Typography color={"error"}>{error}</Typography>
                    </Box>
                </Card>
            </SignInContainer> : <>
                {props.children(config, email, logout)}
            </> }
        </ThemeProvider>
    )
}