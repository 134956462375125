import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import {useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import LogoutIcon from '@mui/icons-material/Logout';
import RestoreIcon from '@mui/icons-material/Restore';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

export const Settings = (props) => {

    const [settingsDialog, setSettingsDialog] = useState( false )
    const [config, setConfig] = useState( JSON.stringify( props.config, null, "\t" ) );

    const handleSettings = () =>{
        setSettingsDialog(true);
    }
    const handleLogout = () =>{
        props.onLogOut();
    }
    const handleClose = () =>{
        setSettingsDialog(false);
    }

    const  handleResetCodeRag = async () =>{
        if ( window.confirm('Are you sure you want to reset the Code RAG?')  )  {
                try {
                    console.debug( "URL " + props?.config?.n8n?.url + '/' + props?.config?.n8n?.codewebhook)
                    const getres = await axios({
                        method: "GET",
                        url: props?.config?.n8n?.url + '/' + props?.config?.n8n?.codewebhook,
                        headers: {
                            Authorization: 'Bearer ' + props?.config?.n8n?.token,
                        },
                    });
                    console.debug('N8N RESPONSE: ', getres);
                }
                catch ( e ){
                    console.error('Failed to reset rag!', e);
                    window.alert('Failed!')
                }
         }
    }

    const  handleResetKBRag = async () =>{
        if ( window.confirm('Are you sure you want to reset the Knowledgebase RAG?')  )  {
                try {
                    console.debug( "URL " + props?.config?.n8n?.url + '/' + props?.config?.n8n?.kbwebhook)
                    const getres = await axios({
                        method: "GET",
                        url: props?.config?.n8n?.url + '/' + props?.config?.n8n?.kbwebhook,
                        headers: {
                            Authorization: 'Bearer ' + props?.config?.n8n?.token,
                        },
                    });
                    console.debug('N8N RESPONSE: ', getres);
                }
                catch ( e ){
                    console.error('Failed to reset rag!', e);
                    window.alert('Failed!')
                }
         }
    }

    const handleSave = () =>{
        let confgJS = {};
        try{
            confgJS = JSON.parse( config );
        }
        catch ( e ){
            alert( 'Invalid JSON' );
            return;
        }

        axios.post('gizmoai/config', confgJS, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                authorization: "Bearer " + props?.config?.token,
            },
        })
            .then(({data}) => {
                console.log(data);
                localStorage.setItem('gizmoai_access_token', JSON.stringify(data));
                handleClose();
                window.location.reload();
            }).catch(e=>{
                alert('Failed to save.')
                console.error(e);
        });

    }

    return <div style={{position: "absolute", top: 8, right: 8,zIndex:100}} ><IconButton size={"small"}
           onClick={handleSettings} title={"Launch Config (v0.1.2)"}><SettingsIcon/></IconButton>
        <IconButton size={"small"} title={"Logout User: " + props.userId + ''}
           onClick={handleLogout}><LogoutIcon/></IconButton>
        <Dialog open={settingsDialog} onClose={handleClose} fullWidth={true}>
            <DialogContent>
                <IconButton size={"small"}
                            onClick={handleResetKBRag} title={"Reset Knowledgebase Database"}><RestoreIcon/></IconButton>
                <IconButton size={"small"}
                            onClick={handleResetCodeRag} title={"Reset Code Database"}><SettingsBackupRestoreIcon/></IconButton>
                <TextField
                    spellCheck={false}
                    autoFocus
                    required
                    margin="dense"
                    id="config"
                    name="config"
                    label="Configuration"
                    fullWidth
                    rows={10}
                    multiline={true}
                    value={config}
                    onChange={(event) => {
                        setConfig(event.target.value);
                    }}
                    type={"text"}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>

        </Dialog>
    </div>

}