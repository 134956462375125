import './App.css';
import {Login} from "./Login";
import {createTheme, IconButton} from "@mui/material";
import {Chat} from "./Chat";
import {Settings} from "./Settings";
function App() {
    const theme = createTheme({
    });

    return (
    <div className="App">
        <Login theme={theme}>{(config, userid, logout)=><>
                    <Settings config={config} userId={userid} onLogOut={logout}></Settings>
                    <Chat config={config} userId={userid}></Chat>
                </>}</Login>
    </div>
  );
}

export default App;
